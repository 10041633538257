import { TestBending } from '../../../Actions/Tests/IronLab/TestBending/ActionTypes';

export const testBendingReducer = (state = [], action) => {
    switch (action.type) {
        case TestBending.DATA:
            return action.payLoad;

        case TestBending.FROMDATA:
            return action.payLoad;

        case TestBending.ADD:
            return [action.payLoad, ...state];

        case TestBending.EDIT:
            return state.map((item) => item.id === action.payLoad.id ? item = { ...action.payLoad } : item);

        case TestBending.DELETE:
            return [...state.filter(item => item.id !== action.payLoad)];

        default:
            return state;
    }
}