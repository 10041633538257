import OrdersActionTypes from "../../Actions/Orders/ActionTypes";

export const OrderCostReducer = (state = {}, action) => {
   switch (action.type) {
      case OrdersActionTypes.FETCH_ORDERCOST:
         return action.payload;

      case OrdersActionTypes.ADD_OR_EDIT_ORDER_DISCCOUNT:
         return action.payload;

      default:
         return state;
   }
};
