import { TestTensile } from '../../../Actions/Tests/IronLab/TestTensile/ActionTypes';

export const testTensileReducer = (state = [], action) => {
    switch (action.type) {
        case TestTensile.DATA:
            return action.payLoad;

        case TestTensile.FROMDATA:
            return action.payLoad;

        case TestTensile.SAVEITEMFROMDATA:
            return action.payLoad.id ?
                [...state.map(item => item.id === action.payLoad.id ? item = action.payLoad : item)] :
                [...state, action.payLoad];

        case TestTensile.ADD:
            return action.payLoad;

        case TestTensile.EDIT:
            return action.payLoad;
            // return state.map((item) => item.id === action.payLoad.id ? item = { ...action.payLoad } : item);

        case TestTensile.DELETE:
            return [...state.filter(item => item !== action.payLoad)];

        default:
            return state;
    }
}