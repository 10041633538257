const PriceListActionTypes = {};

PriceListActionTypes.FETCH_LIST = 'FETCH_PRICELIST';
PriceListActionTypes.FETCH_ITEM = 'FETCH_PRICELISTITEM';
PriceListActionTypes.ADD = 'ADD_PRICELISTITEM';
PriceListActionTypes.EDIT = 'EDIT_PRICELISTITEM';
PriceListActionTypes.DELETE = 'DELETE_PRICELISTITEM';


export default PriceListActionTypes;
