// export const LOADING = 'LOADING';

const LabsActionTypes = {};

LabsActionTypes.FETCH_LABS = "FETCH_LABS";
LabsActionTypes.FETCH_LABITEM = "FETCH_LABITEM";
LabsActionTypes.ADD = "ADD";
LabsActionTypes.EDIT = "EDIT";
LabsActionTypes.DELETE = "DELETE";

export default LabsActionTypes;
