import OrdersActionTypes from "../../Actions/Orders/ActionTypes";

export const ordersReducer = (state = [], action) => {
   switch (action.type) {
      case OrdersActionTypes.FETCH_ALL_ORDERS:
         return action.payload;

      case OrdersActionTypes.FETCH_ORDER:
         return [
            ...state.filter((item) => item.id !== action.payload.id),
            action.payload,
         ];

      case OrdersActionTypes.FETCH_ORDERBYLABID:
         return action.payload;

      case OrdersActionTypes.ADD:
         return [...state, action.payload];

      case OrdersActionTypes.EDIT:
         return [
            ...state.filter((item) => item.id !== action.payload.id),
            action.payload,
         ];

      case OrdersActionTypes.CHANGE_STATUS:
         return [
            ...state.filter((item) => item.id !== action.payload.id),
            action.payload,
         ];

      case OrdersActionTypes.UPDATE_INVOICE:
         return state.map((item) =>
            item.id === action.payload.id
               ? { ...item, ...action.payload }
               : item
         );

      case OrdersActionTypes.DELETE:
         return [...state.filter((item) => item.id !== action.payload.id)];

      case OrdersActionTypes.SEARCH:
         return action.payload;

      default:
         return state;
   }
};

export const labsOrderCountReducer = (state = [], action) => {
   switch (action.type) {
      case OrdersActionTypes.GET_LAB_ORDER_COUNT:
         return action.payload;

      default:
         return state;
   }
};
