import { Materials_HAS_ERRORED, Materials_IS_LOADING, Materials_DATA } from '../../Actions/Materials/ActionTypes';

export const materialsIsLoading = (state = [], action) => {
    switch (action.type) {
        case Materials_IS_LOADING:
            return { ...state, materialsIsLoading: action.payLoad };
        default:
            return state;
    }
}

export const materialsHasErrored = (state = [], action) => {
    switch (action.type) {
        case Materials_HAS_ERRORED:
            return { ...state, materialsHasErrored: action.payLoad };
        default:
            return state;
    }
}

export const materialsData = (state = [], action) => {
    switch (action.type) {
        case Materials_DATA:
            return action.payLoad;
        default:
            return state;
    }
}