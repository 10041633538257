import OrderItemsActionTypes from "../../../Actions/OrderDetails/OrderItems/ActionTypes";

export const orderItemsReducer = (state = [], action) => {
   switch (action.type) {
      case OrderItemsActionTypes.FETCH_ORDER_ITEM_BY_ORDER_ID:
         return state.length &&
            action.payload.length &&
            state.filter((item) => item.id === action.payload[0].id).length > 0
            ? action.payload
            : action.payload.length
            ? [...state, ...action.payload]
            : state;

      case OrderItemsActionTypes.FETCH_ORDER_ITEM_BY_ID:
         return [...state, action.payload];

      case OrderItemsActionTypes.ADD:
         return [...state, action.payload];

      case OrderItemsActionTypes.EDIT:
         return [
            ...state.filter((item) => item.id !== action.payload.id),
            action.payload,
         ];

      case OrderItemsActionTypes.DELETE:
         return [...state.filter((item) => item.id !== action.payload)];

      default:
         return state;
   }
};

export const orderItemByIdReducer = (state = [], action) => {
   switch (action.type) {
      case OrderItemsActionTypes.FETCH_ORDER_ITEM_BY_ID:
         // let obj = { item: action.payload };
         return { ...state, ...action.payload };
      //   [...state, action.payload];

      default:
         return state;
   }
};
