import {FETCH_TESTS} from '../../../Actions/Tests/IronLab/ActionTypes';

export const testsReducer = (state = [], action) => {
    switch (action.type) {
        case FETCH_TESTS:
            return action.payload;
    
        default:
            return state;
    }
}