import UncertaintyTypes from "../../Actions/Uncertainty/ActionTypes";

export const UncertaintyReducer = (state = [], action) => {
   switch (action.type) {
      case UncertaintyTypes.FETCH_UNCERTAINTY:
         return action.payload;

      case UncertaintyTypes.EDIT_UNCERTAINTY:
         return [
            ...state.filter((item) => item.id !== action.payload.id),
            action.payload,
         ];

      default:
         return state;
   }
};
