import LabsActionTypes from '../../Actions/Labs/ActionTypes';

export const labsReducer = (state = [], action) => {
    switch (action.type) {
        case LabsActionTypes.FETCH_LABS:
            return action.payload;
            
        case LabsActionTypes.FETCH_LABITEM:
            return action.payload;
    
        default:
            return state;
    }
}