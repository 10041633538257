import ConfigProvider from "antd/es/config-provider";
import ar_EG from "antd/es/locale/ar_EG";
import en_US from "antd/es/locale/en_US";
import moment from "moment";
import { ar } from "moment/locale/ar";
import { en } from "moment/locale/en-ca";
import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./App.scss";
import UserPermissionsProvider from "./permissions/UserPermissionsProvider";
// import "./shared/scss/antd-rtl.scss";
import "antd/dist/antd.css";

const loading = () => (
   <div className="animated fadeIn pt-3 text-center">Loading...</div>
);

// Containers
const DefaultLayout = React.lazy(() => import("./containers/DefaultLayout"));

// Pages
const Login = React.lazy(() => import("./views/Pages/Login"));
const Register = React.lazy(() => import("./views/Pages/Register"));
const Page404 = React.lazy(() => import("./views/Pages/Page404"));
const Page500 = React.lazy(() => import("./views/Pages/Page500"));

const App = (props) => {
   let initialState = {
      lang: "ar",
      locale: ar_EG,
   };
   const [state, setstate] = useState(initialState);

   useEffect(() => {
      moment.locale(state.lang === "ar" ? ar : en);
   }, [state.lang]);

   const setDir = (locale) => {
      switch (locale) {
         case "ar":
            setstate({ ...state, lang: locale, locale: ar_EG });
            break;
         case "en":
            setstate({ ...state, lang: locale, locale: en_US });
            break;

         default:
            setstate({ ...state, lang: "ar", locale: ar_EG });
            break;
      }

      moment.locale(locale);
   };

   return (
      <ConfigProvider
         direction="rtl"
         //  locale={state.locale}
         // direction={state.locale.locale === "ar" ? "rtl" : "ltr"}
      >
         <Router>
            <React.Suspense fallback={loading()}>
               <Switch>
                  <Route
                     exact
                     path="/login"
                     name="Login Page"
                     render={(props) => <Login {...props} />}
                  />
                  <Route
                     exact
                     path="/register"
                     name="Register Page"
                     render={(props) => <Register {...props} />}
                  />
                  <Route
                     exact
                     path="/404"
                     name="Page 404"
                     render={(props) => <Page404 {...props} />}
                  />
                  <Route
                     exact
                     path="/500"
                     name="Page 500"
                     render={(props) => <Page500 {...props} />}
                  />
                  <Route
                     path="/"
                     name="Home"
                     render={(props) => (
                        <UserPermissionsProvider>
                           <DefaultLayout {...props} setDir={setDir} />
                        </UserPermissionsProvider>
                     )}
                  />
               </Switch>
            </React.Suspense>
         </Router>
      </ConfigProvider>
   );
};

export default App;
