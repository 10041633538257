import StandardsActionTypes from '../../Actions/Standards/ActionTypes';

export const standardLimitsGradeReducer = (state = [], action) => {
    switch (action.type) {

        case StandardsActionTypes.FETCH_STANDARD_LIMITS_GRADE:
            return [...action.payload];

        default:
            return state;
    }
}