export default [
    "AccountMangment.Register.policy",
    "AccountMangment.CreateRole.policy",
    "AccountMangment.DeleteRole.policy",
    "AdditionalFees.Get.policy",
    "AdditionalFees.GetAll.policy",
    "AdditionalFees.Add.policy",
    "AdditionalFees.Edit.policy",
    "AdditionalFees.Delete.policy",
    "Bill.Get.policy",
    "Bill.GetAll.policy",
    "Bill.Add.policy",
    "Bill.Edit.policy",
    "Bill.Delete.policy",
    "ForceMachine.Get.policy",
    "ForceMachine.GetAll.policy",
    "ForceMachine.Add.policy",
    "ForceMachine.Edit.policy",
    "ForceMachine.Delete.policy",
    "Lab.Get.policy",
    "Lab.GetAll.policy",
    "Lab.Add.policy",
    "Lab.Edit.policy",
    "Lab.Delete.policy",
    "Material.Get.policy",
    "Material.GetAll.policy",
    "Material.Add.policy",
    "Material.Edit.policy",
    "Material.Delete.policy",
    "MaterialType.Get.policy",
    "MaterialType.GetAll.policy",
    "MaterialType.Add.policy",
    "MaterialType.Edit.policy",
    "MaterialType.Delete.policy",
    "Order.Get.policy",
    "Order.GetAll.policy",
    "Order.GetAllBylabId.policy",
    "Order.Add.policy",
    "Order.Edit.policy",
    "Order.EditOrderDiscount.policy",
    "Order.EditOrderTax.policy",
    "Order.GetOrdersCountsByStatus.policy",
    "Order.GetStatusOrdersCounts.policy",
    "Order.UpdateInvoice.policy",
    "Order.ChangeStatus.policy",
    "Order.Delete.policy",
    "Order.Search.policy",
    "Order.GetOrderCost.policy",
    "OrderItem.Get.policy",
    "OrderItem.GetAll.policy",
    "OrderItem.Add.policy",
    "OrderItem.Edit.policy",
    "OrderItem.Delete.policy",
    "OrderItem.UploadReport.policy",
    "PriceList.Get.policy",
    "PriceList.GetAll.policy",
    "PriceList.Add.policy",
    "PriceList.Edit.policy",
    "PriceList.Delete.policy",
    "Report.GetSteelReport.policy",
    "Report.GetTesileReport.policy",
    "Report.GetBendingReport.policy",
    "Report.GetCoverLetter.policy",
    "Report.ChangePrintReportFlag.policy",
    "Report.ChangeOrderPrintReportFlag.policy",
    "Resolution.Get.policy",
    "Resolution.GetAll.policy",
    "Resolution.Add.policy",
    "Resolution.Edit.policy",
    "Resolution.Delete.policy",
    "Settings.GetSetting.policy",
    "Settings.AddSetting.policy",
    "Settings.EditSetting.policy",
    "Standard.Get.policy",
    "Standard.GetAll.policy",
    "Standard.Add.policy",
    "Standard.Edit.policy",
    "Standard.Delete.policy",
    "StandardLimit.Get.policy",
    "StandardLimit.GetAll.policy",
    "StandardLimit.GetGradesByStandard.policy",
    "StandardLimit.Add.policy",
    "StandardLimit.Edit.policy",
    "StandardLimit.Delete.policy",
    "SteelReport.Add.policy",
    "SteelReport.Edit.policy",
    "SteelReport.Get.policy",
    "SteelReport.Delete.policy",
    "SteelReport.Approve.policy",
    "SteelReport.Reject.policy",
    "SteelReport.AddOrEditReadings.policy",
    "SteelReport.EditReadings.policy",
    "SteelReport.GetNotes.policy",
    "Test.Get.policy",
    "Test.GetAll.policy",
    "Uncertainty.Get.policy",
    "Uncertainty.GetAll.policy",
    "Uncertainty.Add.policy",
    "Uncertainty.Edit.policy",
    "Uncertainty.Delete.policy",
    "UserAccount.AddUser.policy",
    "UserAccount.EditUser.policy",
    "UserAccount.EditStatus.policy",
    "UserAccount.EditRole.policy",
    "UserAccount.GetUser.policy",
    "UserAccount.GetNotMappedProviders.policy",
    "UserAccount.CurrentUser.policy",
    "UserAccount.GetUserByRole.policy",
    "UserAccount.ChangePassword",
    "UserRole.List",
    "UserRole.Add",
    "UserRole.Edit",
    "UserRole.Delete",
    "RolePerm.List",
    "RolePerm.GetForUser",
    "RolePerm.SetForUser",
    "RolePerm.GetForRole",
    "RolePerm.SetForRole"
];
