import ReportActionTypes from "../../Actions/Report/ActionTypes";

export const ReportReducer = (
   state = { allData: [], BendingData: [], TensileData: [], CoverLetter: [] },
   action
) => {
   switch (action.type) {
      case ReportActionTypes.GET_STEEL_REPORT_BY_ORDER_ID:
         return { ...state, allData: action.payload };
      case ReportActionTypes.GET_BENDING_REPORT:
         return { ...state, BendingData: action.payload };
      case ReportActionTypes.GET_TENSILE_REPORT:
         return { ...state, TensileData: action.payload };
      case ReportActionTypes.GET_REPORT_COVER_LETTER:
         return { ...state, CoverLetter: action.payload };

      default:
         return state;
   }
};
