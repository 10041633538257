import StandardsActionTypes from '../../Actions/Standards/ActionTypes';

export const standardLimitsReducer = (state = [], action) => {
    switch (action.type) {
        case StandardsActionTypes.FETCH_ALL_STANDARD_LIMITS:
            return action.payload;

        // case FETCH_PRICELISTITEM:
        //     return [...state, action.payload];
        //         //[...state.StandardLimits.filter(item => item !== action.payload), action.payload] 

        // case StandardsActionTypes.FETCH_STANDARD_LIMITS_GRADE:
        //     return [...action.payload];

        case StandardsActionTypes.ADD_STANDARD_LIMITS:
            return [...state, action.payload];

        case StandardsActionTypes.EDIT_STANDARD_LIMITS:
            return [...state.filter(item => item.id !== action.payload.id), action.payload];

        case StandardsActionTypes.DELETE_STANDARD_LIMITS:
            return [...state.filter(item => item !== action.payload)];

        default:
            return state;
    }
}