import React, { createContext, useState, useEffect } from "react";
import Api, { axe } from "./shared/Api";

export const SettingsContext = createContext();

const AppSettingsProvider = (props) => {
   let settings = {
      REACT_APP_SITEURL: process.env.REACT_APP_SITEURL,
      REACT_APP_BASEURL: process.env.REACT_APP_BASEURL,
      REACT_APP_FILE_UPLOAD: process.env.REACT_APP_FILE_UPLOAD,
      REACT_APP_FILE_PATH: process.env.REACT_APP_FILE_PATH,
   };

   const [data, setData] = useState(settings);

   useEffect(() => {
      try {
         let fet = async () => {
            let getF = await axe.get(
               window.location.origin + "/react.settings.json"
            );
            if (getF) {
               setData(getF.data);
               Api.defaults.baseURL =
                  getF.data.REACT_APP_BASEURL || settings.REACT_APP_BASEURL;
            }
         };
         fet();
      } catch (error) {
         console.log(error);
      }

      // eslint-disable-next-line
   }, []);

   return (
      <SettingsContext.Provider value={{ ...data }}>
         {props.children}
      </SettingsContext.Provider>
   );
};

export default AppSettingsProvider;
