import steelReportActionTypes from '../../Actions/SteelReport/ActionTypes';

export const steelReportReducer = (state = { item: {}, rejectNotes: [] }, action) => {
   switch (action.type) {
      case steelReportActionTypes.FETCH_STEELREPORT:
         return { ...state, item: action.payload };

      case steelReportActionTypes.ADD:
         return { ...state, item: { ...state.item, ...action.payload } };

      case steelReportActionTypes.EDIT:
         return { ...state, item: { ...state.item, ...action.payload } };

      case steelReportActionTypes.DELETE:
         return {};

      case steelReportActionTypes.GET_REJECT_NOTES:
         return { ...state, rejectNotes: action.payload };

      case steelReportActionTypes.APPROVE:
         return { ...state, item: { ...state.item, statusId: action.payload } };

      case steelReportActionTypes.REJECT:
         return { ...state, item: { ...state.item, statusId: action.payload } };

      default:
         return state;
   }
}
