import UserRoleActionTypes from "../../Actions/UserRole/ActionTypes";

export const userRoleReducer = (state = [], action) => {
   switch (action.type) {
      case UserRoleActionTypes.FETCH_USER_ROLES:
         return action.payload.data;

      case UserRoleActionTypes.FETCH_USER_ROLE:
         return [
            ...state.filter((item) => item.id !== action.payload.id),
            action.payload,
         ];
      // return [...state, action.payload];

      case UserRoleActionTypes.ADD_USER_ROLE:
         return [...state, action.payload];

      case UserRoleActionTypes.EDIT_USER_ROLE:
         return [
            ...state.filter((item) => item.id !== action.payload.id),
            action.payload,
         ];

      case UserRoleActionTypes.DELETE_USER_ROLE:
         return [...state.filter((item) => item !== action.payload)];

      default:
         return state;
   }
};
