import PermissionActionTypes from "../../Actions/Permission/ActionTypes";

export const permissionReducer = (
   state = { all: [], byRole: [], byUser: [] },
   action
) => {
   switch (action.type) {
      case PermissionActionTypes.FETCH_PERMISSIONS:
         return { ...state, all: action.payload };

      case PermissionActionTypes.FETCH_PERMISSION_BY_ROLE:
         return { ...state, byRole: action.payload };

      case PermissionActionTypes.ADD_ROLE_PERMISSION:
         return { ...state, byRole: [...state.byRole, ...action.payload] };

      case PermissionActionTypes.FETCH_PERMISSION_BY_USER:
         return { ...state, byUser: action.payload };

      case PermissionActionTypes.ADD_USER_PERMISSION:
         return { ...state, byUser: [...state.byUser, ...action.payload] };

      default:
         return state;
   }
};
