const StandardsActionTypes = {};

StandardsActionTypes.FETCH_ALL_STANDARDS = 'FETCH_ALL_STANDARDS';
StandardsActionTypes.FETCH_ITEM = 'FETCH_STANDARDITEM';
StandardsActionTypes.ADD = 'ADD_STANDARDITEM';
StandardsActionTypes.EDIT = 'EDIT_STANDARDITEM';
StandardsActionTypes.DELETE = 'DELETE_STANDARDITEM';

StandardsActionTypes.FETCH_ALL_STANDARD_LIMITS = 'FETCH_ALL_STANDARD_LIMITS';
StandardsActionTypes.FETCH_STANDARD_LIMITS = 'FETCH_STANDARD_LIMITS';
StandardsActionTypes.FETCH_STANDARD_LIMITS_GRADE = 'FETCH_STANDARD_LIMITS_GRADE';
StandardsActionTypes.ADD_STANDARD_LIMITS = 'ADD_STANDARD_LIMITS';
StandardsActionTypes.EDIT_STANDARD_LIMITS = 'EDIT_STANDARD_LIMITS';
StandardsActionTypes.DELETE_STANDARD_LIMITS = 'DELETE_STANDARD_LIMITS';

export default StandardsActionTypes;
