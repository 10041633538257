import { MaterialsTypes_HAS_ERRORED, MaterialsTypes_IS_LOADING, MaterialsTypes_DATA } from '../../Actions/Materials/ActionTypes';

export const materialsTypesIsLoading = (state = [], action) => {
    switch (action.type) {
        case MaterialsTypes_IS_LOADING:
            return { ...state, materialsTypesIsLoading: action.payLoad };
        default:
            return state;
    }
}

export const materialsTypesHasErrored = (state = [], action) => {
    switch (action.type) {
        case MaterialsTypes_HAS_ERRORED:
            return { ...state, materialsTypesHasErrored: action.payLoad };
        default:
            return state;
    }
}

export const materialsTypesData = (state = [], action) => {
    switch (action.type) {
        case MaterialsTypes_DATA:
            return action.payLoad;
        default:
            return state;
    }
}