import OrderAdditionalFeesActionTypes from '../../../Actions/OrderDetails/OrderAdditionalFees/ActionTypes';

export const orderAdditionalFeesReducer = (state = [], action) => {
    switch (action.type) {
        case OrderAdditionalFeesActionTypes.FETCH_ORDER_ADDITIONAL_FEES:
            return action.payload;

        case OrderAdditionalFeesActionTypes.FETCH_ORDER_ADDITIONAL_FEES_BY_ORDER_ID:
            // return action.payload;
            return state.length && action.payload.length && state.filter(item => item.id === action.payload[0].id).length > 0 ? action.payload : action.payload.length ? [...state, ...action.payload] : state;

        case OrderAdditionalFeesActionTypes.ADD:
            return [...state, action.payload];

        case OrderAdditionalFeesActionTypes.EDIT:
            return [...state.filter(item => item.id !== action.payload.id), action.payload];

        case OrderAdditionalFeesActionTypes.DELETE:
            return [...state.filter(item => item !== action.payload)];

        default:
            return state;
    }
}