const SteelReportActionTypes = {};

SteelReportActionTypes.FETCH_STEELREPORT = 'FETCH_STEELREPORT';
SteelReportActionTypes.ADD = 'ADD_STEELREPORTITEM';
SteelReportActionTypes.EDIT = 'EDIT_STEELREPORTITEM';
SteelReportActionTypes.DELETE = 'DELETE_STEELREPORTITEM';
SteelReportActionTypes.APPROVE = 'APPROVE_STEELREPORTITEM';
SteelReportActionTypes.REJECT = 'REJECT_STEELREPORTITEM';
SteelReportActionTypes.GET_REJECT_NOTES = 'GET_REJECT_NOTES';
SteelReportActionTypes.ADD_TESNILE_READING_TEST = 'ADD_TESNILE_READING_TEST';
SteelReportActionTypes.EDIT_TESNILE_READING_TEST = 'EDIT_TESNILE_READING_TEST';


export default SteelReportActionTypes;
