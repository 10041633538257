import PriceListActionTypes from '../../Actions/PriceList/ActionTypes';

export const priceListReducer = (state = [], action) => {
    switch (action.type) {
        case PriceListActionTypes.FETCH_LIST:
            return action.payload;
            
        // case FETCH_PRICELISTITEM:
        //     return [...state, action.payload];
        //         //[...state.priceList.filter(item => item !== action.payload), action.payload] 
        
        case PriceListActionTypes.ADD:
            return [...state, action.payload];

        case PriceListActionTypes.EDIT:
            return [...state.filter(item => item.id !== action.payload.id), action.payload];

        case PriceListActionTypes.DELETE:
            return [...state.filter(item => item !== action.payload)];
            
        default:
            return state;
    }
}