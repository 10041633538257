import ForceMachineTypes from "../../Actions/ForceMachine/ActionTypes";

export const ForceMachineReducer = (state = [], action) => {
   switch (action.type) {
      case ForceMachineTypes.FETCH_FORCE_MACHINE:
         return action.payload;

      case ForceMachineTypes.EDIT_FORCE_MACHINE:
         return [
            ...state.filter((item) => item.id !== action.payload.id),
            action.payload,
         ];

      default:
         return state;
   }
};
