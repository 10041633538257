const OrderItemsActionTypes = {};

OrderItemsActionTypes.FETCH_ORDER_ITEM_BY_ORDER_ID = 'FETCH_ORDER_ITEM_BY_ORDER_ID';
OrderItemsActionTypes.FETCH_ORDER_ITEM_BY_ID = 'FETCH_ORDER_ITEM_BY_ID';
OrderItemsActionTypes.ADD = 'ADD_ORDER_ITEM';
OrderItemsActionTypes.EDIT = 'EDIT_ORDER_ITEM';
OrderItemsActionTypes.UPLOADREPORT = "UPLOADREPORT";
OrderItemsActionTypes.EDITORDERITEMFILES = "EDITORDERITEMFILES";
OrderItemsActionTypes.DELETE = 'DELETE_ORDER_ITEM';

export default OrderItemsActionTypes;