import AuthenticateActionTypes from "../../Actions/Authenticate/ActionTypes";

export const NotAuthorizedReducer = (state = false, action) => {
   switch (action.type) {
      case AuthenticateActionTypes.NOT_AUTHORIZED:
         return true;

      default:
         return state;
   }
};
