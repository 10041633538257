import { combineReducers } from "redux";

import { auditReducer } from "./Audit/AuditReducer";
import { priceListReducer } from "./PriceList/PriceListReducer";
import { labsReducer } from "./Labs/LabsReducer";
import { testsReducer } from "./Tests/IronLab/TestsReducer";
import { ordersReducer, labsOrderCountReducer } from "./Orders/OrdersReducer";
import { OrderCostReducer } from "./Orders/OrderCostReducer";
import { orderDetailsReducer } from "./OrderDetails/OrderDetailsReducer";
import { orderAdditionalFeesReducer } from "./OrderDetails/OrderAdditionalFees/OrderAdditionalFeesReducer";
import {
   orderItemsReducer,
   orderItemByIdReducer,
} from "./OrderDetails/OrderItems/OrderItemsReducer";
import { standardReducer } from "./Standards/StandardsReducer";
import { standardLimitsReducer } from "./Standards/StandardLimitsReducer";
import { standardLimitsGradeReducer } from "./Standards/StandardLimitsGradeReducer";
import { steelReportReducer } from "./SteelReport/SteelReportReducer";
import { materialsData } from "./Materials/MaterialsReducer";
import { materialsTypesData } from "./Materials/MaterialsTypesReducer";
import { testTensileReducer } from "./Tests/IronLab/TestTensileReducer";
import { testBendingReducer } from "./Tests/IronLab/TestBendingReducer";
import { UserReducer } from "./User/UserReducer";
import { permissionReducer } from "./Permission/PermissionReducer";
import { userRoleReducer } from "./UserRole/UserRoleReducer";
import { NotAuthorizedReducer } from "./Authenticate/NotAuthorizedReducer";
import { ForceMachineReducer } from "./ForceMachine/ForceMachinReducer";
import { ResolutionReducer } from "./Resolution/ResolutionReducer";
import { UncertaintyReducer } from "./Uncertainty/UncertaintyReducer";
import { ReportReducer } from "./Report/ReportReducer";

const appReducer = combineReducers({
   notAuthorized: NotAuthorizedReducer,
   audits: auditReducer,
   priceList: priceListReducer,
   labs: labsReducer,
   tests: testsReducer,
   orders: ordersReducer,
   labsOrdersCount: labsOrderCountReducer,
   orderCost: OrderCostReducer,
   orderDetails: orderDetailsReducer,
   orderAdditionalFees: orderAdditionalFeesReducer,
   orderItems: orderItemsReducer,
   orderItemById: orderItemByIdReducer,
   standards: standardReducer,
   standardLimits: standardLimitsReducer,
   standardLimitsGrade: standardLimitsGradeReducer,
   steelReport: steelReportReducer,
   materials: materialsData,
   materialsTypes: materialsTypesData,
   testTensile: testTensileReducer,
   testBending: testBendingReducer,
   users: UserReducer,
   permissions: permissionReducer,
   userRoles: userRoleReducer,
   ForceMachine: ForceMachineReducer,
   Resolution: ResolutionReducer,
   Uncertainty: UncertaintyReducer,
   Report: ReportReducer,
});

export const rootReducer = (state, action) => {
   if (action.type === "RESET_STORE") {
      state = action.payload || {};
   }

   return appReducer(state, action);
};

export default rootReducer;
