const OrdersActionTypes = {};

OrdersActionTypes.FETCH_ALL_ORDERS = "FETCH_ALL_ORDERS";
OrdersActionTypes.FETCH_ORDER = "FETCH_ORDER";
OrdersActionTypes.FETCH_ORDERBYLABID = "FETCH_ORDERBYLABID";
OrdersActionTypes.FETCH_ORDERCOST = "FETCH_ORDERCOST";
OrdersActionTypes.ADD_OR_EDIT_ORDER_DISCCOUNT = "ADD_OR_EDIT_ORDER_DISCCOUNT";
OrdersActionTypes.ADD = "ADD_ORDERITEM";
OrdersActionTypes.EDIT = "EDIT_ORDERITEM";
OrdersActionTypes.CHANGE_STATUS = "CHANGE_STATUS";
OrdersActionTypes.UPDATE_INVOICE = "UPDATE_INVOICE";
OrdersActionTypes.DELETE = "DELETE_ORDERITEM";
OrdersActionTypes.SEARCH = "SEARCH_ORDERITEMS";
OrdersActionTypes.GET_LAB_ORDER_COUNT = "GET_LAB_ORDER_COUNT";

export default OrdersActionTypes;
