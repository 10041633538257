import {FETCH_ORDERDETAILS} from '../../Actions/OrderDetails/ActionTypes';

export const orderDetailsReducer = (state = [], action) => {
    switch (action.type) {
        case FETCH_ORDERDETAILS:
            return action.payload;
    
        default:
            return state;
    }
}