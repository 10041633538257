import ResolutionTypes from "../../Actions/Resolution/ActionTypes";

export const ResolutionReducer = (state = [], action) => {
   switch (action.type) {
      case ResolutionTypes.FETCH_RESOLUTION:
         return action.payload;

      case ResolutionTypes.EDIT_RESOLUTION:
         return [
            ...state.filter((item) => item.id !== action.payload.id),
            action.payload,
         ];

      default:
         return state;
   }
};
